.qr-row {
  flex: 1;
  display: flex;
  justify-content: center;
}

.qr-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.custom-input-group-container {
  margin-bottom: 20px;

  .custom-input-label-container {
    margin-bottom: 10px;
    height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

    .custom-input-label {
      color: #FFAE00;
    }

    .custom-input-optional {
      color: #8E8E93;
      margin-left: 5px;
    }

    .custom-input-fiat-conversion {
      text-align: right;
      color: #8E8E93;

      .decimal {
        opacity: 0.5;
      }
    }
  }

  .custom-input-container {
    background-color: #1C1C1D;
    border-radius: 10px;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0px 18px;

    .custom-input {
      height: 100%;
      background-color: transparent;
      border-style: none;
      margin-right: 10px;
      color: white;
      font-family: Neue Haas Grotesk Display Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      width: 50%;
      flex: 3;
    }

    ::placeholder {
      color: rgba(142, 142, 147, 1);
    }

    .custom-input-append-container {
      text-align: right;
      display: flex;
      align-items: center;

      .custom-input-append {
        color: #FFAE00;
        font-family: Neue Haas Grotesk Display Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        text-align: right;
        margin-right: 10px;
        white-space: nowrap;

        .custom-input-append-icon {
          margin-right: 8.24px;
        }
      }
    }
  }
}

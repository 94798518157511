.halos-container {
  .halo-top-left {
    position: fixed;
    top: -650px;
    left: -200px;
    z-index: -1;
  }

  .halo-top {
    position: fixed;
    top: -200px;
    left: 20%;
    z-index: -1;
  }

  .halo-footer {
    position: fixed;
    bottom: -150px;
    right: -40%;
    z-index: -1;
  }
}

.webpage-container {
  .full-webpage-row1 {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-content: center;

    .info-col {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .info-col-content {
        max-width: 320px;
        padding: 0px 20px 0px 20px;
        max-width: 469px;

        img {
          max-width: 100%;
          margin-bottom: 44px;
        }
      }
    }

    .widget-col {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }

  .webpage-heading {
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.4px;
    background: #ffae00;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 44px;
  }

  .webpage-subheading {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: rgba(255, 255, 255, 0.98);
    margin-bottom: 22px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #8E8E93;
  }

  .info-card-col {
    display: flex;
    justify-content: center;

    .info-card {
      background: rgba(16, 16, 16, 0.92);
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
      border-radius: 26px;
      max-width: 480px;
      padding: 40px;
      margin-bottom: 40px;

      .info-card-heading {
        font-weight: 600;
        font-size: 24px;
        letter-spacing: -0.5px;
        color: rgba(255, 255, 255, 0.98);
      }
    }
  }
}

.page-heading {
  margin-bottom: 20px;

  span {
    font-family: Neue Haas Grotesk Display Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.4px;
    background: #FFAE00;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}



.value-group-container {
  flex: 1;

  .value-group-row1 {
    .value-group-label {
      font-size: 15px;
      line-height: 15px;
      font-weight: 600;
      color: #FFAE00;
    }

    .value-group-convert {
      margin-left: 10px;
      color: #8E8E93;

      .bitcoin-value {
        margin-left: 10px;
      }

      .decimal {
        opacity: 0.5;
      }
    }
  }

  .value-group-row2 {
    margin-top: 6px;
    display: flex;
    align-items: center;

    .value-group-sats {
      font-size: 21px;
      font-size: 21px;
      color: white;
      margin-left: 13.24px;
    }

    > .lg {
      line-height: 36px;
      font-size: 36px;
    }

    .value-group-icon-md {

    }

    .value-group-icon-lg {
      height: 22.11px;
      width: auto;
    }
  }
}

// @see https://stackoverflow.com/questions/11002820/why-should-we-include-ttf-eot-woff-svg-in-a-font-face

@import './style/variables';
@import "~bootstrap/scss/bootstrap";
@import './style/bootswatch';

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

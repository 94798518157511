.confirmation-top-half {
  flex: 1;
  padding-bottom: 0px;

  .confirmation-value-groups {
    display: flex;
    margin-bottom: 20px;
  }

  .confirmation-message {
    margin-top: 20px;
    color: #8E8E93;
    font-size: 15px;
    line-height: 22px;

    .confirmation-message-highlight {
      color: white;
      font-weight: 600;
    }
  }
}

.pay-now-button-container {
  display: flex;
  justify-content: center;
}

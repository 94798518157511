.claim-channel-top-divider {
  margin-top: 0px;
}

.claim-channel-container {
  .claim-channel-button-spacer {
    margin-top: 21px;
  }

  .claim-channel-title-container {
    display: flex;
    align-content: space-between;

    .channel-claim-fiat-conversion {
      margin-left: 10px;
      margin-right: 10px;
      text-align: right;
      color: #8E8E93;

      .decimal {
        opacity: 0.5;
      }
    }
  }

  .claim-channel-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    color: #FFAE00;
    margin-bottom: 10px;
  }

  .claim-channel-top {
    display: flex;
    @media (max-width:480px)  {
      flex-direction: column;
    }

    .claim-channel-qr {
      margin-bottom: 20px;
    }

    .claim-channel-details {
      padding-left: 20px;
      overflow: hidden;

      .claim-channel-address {
        font-size: 15px;
        color: #8E8E93;
      }
    }
  }

  .claim-channel-manual-action-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .claim-channel-middle {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .claim-channel-middle-col {
      flex: 1;
    }

    .claim-channel-middle-value {
      font-size: 21px;
      color: #FFFFFF;

      .claim-channel-middle-value-icon {
        margin-right: 13px;
      }
    }
  }

  .claim-channel-bottom-message {
    font-size: 15px;
    color: #8E8E93;
    margin-top: 20px;

    .highlight {
      color: white;
      font-weight: 600;
    }
  }
}

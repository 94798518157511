.payment-page-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.payment-page-subheading {
  font-weight: 600;
  font-size: 15px;
  color: #FFAE00;
}

@import './custom-themes.scss';

h1 {
	font-weight: 600;
	line-height: 80px;
	letter-spacing: -0.5px;
	font-size: 60px;
}

p {
	font-weight: 400;
}

.form-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #000000;
	font-weight: 600;
	height: 56px;
	border-style: none;
	background: #FFAE00;
	border-radius: 58px;
	font-size: 17px;
	padding: 15px 30px;
}

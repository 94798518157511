.order-state-container {
  flex: 1;

  .order-state-support-button-container {
    display: flex;
    justify-content: center;

    .order-state-support-button-spacer {
      width: 10px;
    }
  }

  .order-state-message {
    font-size: 15px;
    color: #8E8E93;

    .highlight {
      color: white;
      font-weight: 600;
    }

    a {
      color: #FFAE00;
    }
  }

  .value-group-row {
    display: flex;
    margin-bottom: 20px;
  }
}

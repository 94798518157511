.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  .footer-logo {
    width: 127.72px;
  }

  .footer-text {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #8E8E93;
    opacity: 0.6;
  }
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #3a3a3c;
	border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ffae00;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(247, 147, 26, 0.64);
}

.orders-container {
	overflow-y: auto;
	height: 100%;
	max-height: 540px;

	.order-list-item {
		.order-list-item-date {
			font-weight: 600;
			font-size: 24px;
			letter-spacing: -0.5px;
			color: #ffffff;
			margin-bottom: 20px;
		}

		.order-list-content {
			display: flex;
			align-items: flex-end;

			.order-list-details {
				flex: 1;
				display: flex;
				margin-right: 15px;
				flex-direction: column;

				.order-status-heading {
					font-weight: 600;
					font-size: 15px;
					color: #FFAE00;
					margin-bottom: 10px;
				}

				.order-status-value {
					font-size: 21px;
					line-height: 24px;
					color: #ffffff;
					font-weight: 200;

					.order-status-icon {
						margin-right: 13.24px;
					}
				}
			}
		}
	}

	.with-scrollbar {
		padding-right: 20px;
	}
}

.custom-input-tooltip-container {
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: white;

  h4 {
    font-size: 15px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    font-weight: 200;
  }

  padding: 20px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;

  .custom-checkbox {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: #8E8E93;
  }

  .checked {
    background-color: #75BF72;
    border-width: 1px;
  }

  .custom-label {
    color: #8E8E93;
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;

    a {
      color: #FFAE00;
      text-decoration: none;
    }
  }
}

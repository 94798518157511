.form-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;

  .form-fields {
    flex: 1
  }

  .button-container {
    //flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.form-group {}

.input-addon {
  //background-color: transparent;
  height: 50px;
}

.icon-ring-container {
  display: flex;
  justify-content: center;
  padding: 40px 0px 40px 0px;
}

.icon-ring {
  border-radius: 120px;
  width: 120px;
  height: 120px;
  mix-blend-mode: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-ring-error {
  @extend .icon-ring;
  mix-blend-mode: normal;
  box-shadow: 0px 1px 10px rgba(223, 100, 53, 0.6), 0px 4px 116px rgba(199, 70, 136, 0.4);
  background: linear-gradient(90deg, rgba(228, 103, 53, 0.2) 0%, rgba(201, 50, 80, 0.2) 70.83%, rgba(202, 72, 140, 0.2) 100%);
  border: rgba(201, 50, 80, 0.98) 2px solid;
}

.icon-ring-pending {
  @extend .icon-ring;
  background: rgba(255, 210, 0, 0.16);
  border: 2px solid #FFAE00;
  box-shadow: 0px 1px 10px rgba(255, 210, 0, 0.3), 0px 4px 116px rgba(255, 210, 0, 0.4);
}

.icon-ring-success {
  @extend .icon-ring;
  background: rgba(117, 191, 114, 0.16);
  border: 2px solid #75BF72;
  box-shadow: 0px 1px 10px rgba(117, 191, 114, 0.3), 0px 4px 116px rgba(117, 191, 114, 0.4);
}

.icon-ring-neutral {
  @extend .icon-ring;
  background: rgba(50, 50, 50, 0.53);
  border: 2px solid #a4a4a4;
  box-shadow: 0px 1px 10px rgba(96, 96, 96, 0.3), 0px 4px 116px rgba(204, 204, 204, 0.4);
}

.icon-ring-neutral-line {
  width: 118.79px;
  height: 2px;
  display: block;
  background-color: #a4a4a4;
  transform: rotate(135deg);
  z-index: 2;
  position: absolute;
}

.icon-ring-image {
  display: block;
  z-index: 1;
}
